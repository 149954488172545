body, html {
    height: 100%;
    margin: 0;
  }

.bg{
    background-image: url("../../images/61-IMG_7458-Enhanced-NR.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
}

.font-main{
  font-family: 'Monoton';
}

.lines-button {
  position: relative;
  float:right;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 96px;
  height: 56px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.lines-button:focus {
  outline: none;
}
.lines-button span {
  display: block;
  position: absolute;
  left: 23px;
  right: 23px;
  height: 6px;
  background: white;
  border-radius: 0.57143rem;
}
.lines-button span::before, .lines-button span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: white;
  border-radius: 0.57143rem;
  content:"";
}
.lines-button span::before {
  top: -15px;
}
.lines-button span::after {
  bottom: -15px;
}
.lines {
  background: none;
}
.lines span {
  -webkit-transition: background 0s 0.3s;
  transition: background 0s 0.3s;
}
.lines span::before, .lines span::after {
  -webkit-transition-duration: 0.3s, 0.3s;
  transition-duration: 0.3s, 0.3s;
}
.lines span::before {
  -webkit-transition-property: top, -webkit-transform;
  transition-property: top, transform;
}
.lines span::after {
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
}
.lines.close {
  background: none;
}
.lines.close span {
  visibility: hidden;
}
.lines.close span::before {
  top: 0;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  visibility: visible;
  background-color: #fff;
}
.lines.close span::after {
  bottom: 0;
  -webkit-transform: rotate(-225deg);
  -ms-transform: rotate(-225deg);
  transform: rotate(-225deg);
  visibility: visible;
  background-color: #fff;
}