body {
    background: #000;
    line-height: 40px;
    margin: 10;
  }
  
  .App {
    height: 75vh;
    margin-top: 15vh;
  }
  
  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .formRow {
    margin: 10px 0 20px 0;
  }


  .formInput {
    padding: 15px 10px;
 
  }
  
  .errorMessage {
    color: #f45532;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
  
  .submit-btn {
    cursor: pointer;
    padding: 15px 50px;
    border-radius: 10px;
    margin: auto auto 25px auto;
    width: 200px;
    background-color: #10593a;
    color: white;

  }

  .submit-btn:hover {
    background-color: #000;
  }